.coupon {
  display: flex;
  align-items: center;
}

/* Style the close button (span) */
.close {
  cursor: pointer;
  position: absolute;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  background: var(--primary-color);
  color: var(--secondary-color);
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
}

.tooltip {
  position: relative;
  display: inline-block;
  color: var(--secondary-color);
  opacity: 1;
  line-height: inherit;
  font-size: inherit;
  z-index: inherit;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  bottom: 130%;
  left: 50%;
  background-color: #555;
  color: #fff;
  width: 170px;
  font-size: smaller;
  /* font-weight: bold; */
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  bottom: -100%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
  top: -35%;
  transform: rotate(180deg);
}