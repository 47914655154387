@import "./base";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h-30 {
  height: 30px;
}

.w-30 {
  width: 30px;
}

.boxShadow {
  box-shadow: 0 0 2px #9d9d9d;
}

.cursor-pointer {
  cursor: pointer;
}

// input :disabled text colot change wrap a classname disinput
.disinput {
  input:disabled {
    color: #000000 !important;
  }
  &:disabled {
    color: #000000 !important;
  }
}

.removeShadow {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -goog-ms-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.pnone__::before {
  content: none;
}
.pnone__::after {
  content: none;
}

::-webkit-scrollbar {
  display: none;
}

.center_div {
  flex: 1;
  overflow: hidden;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.center_varticle {
  flex: 1;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
